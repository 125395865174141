<script lang="ts" setup>
import { computed } from "vue";
import XIcon from "@/components/icons/socialMedia/XIcon.vue";
import IGSolidIcon from "@/components/icons/socialMedia/IGSolidIcon.vue";
import TTIcon from "@/components/icons/socialMedia/TTIcon.vue";
import { useI18n } from "vue-i18n";

defineOptions({
  name: "NavigationFooter",
});

const { t } = useI18n();

const linkTOS = "https://kf-infra.com/tos";
const linkPolicy = "https://kf-infra.com/privacy";

const navigation = computed(() => {
  return {
    // TODO: CHECK LINKS legal & solutions
    solutions: [
      { name: t("footer.blog"), href: "#" },
      { name: t("footer.academy"), href: "#" },
      { name: t("footer.faq"), href: "#" },
      { name: t("footer.support"), href: "#" },
    ],
    legal: [
      { name: t("footer.policy"), href: linkPolicy },
      { name: t("footer.terms"), href: linkTOS },
    ],
    social: [
      {
        name: "Instagram",
        href: "https://www.instagram.com/keyfluenz",
        icon: IGSolidIcon,
      },
      {
        name: "X",
        href: "https://twitter.com/KeyFluenz",
        icon: XIcon,
      },
      {
        name: "X",
        href: "https://www.tiktok.com/@keyfluenz?_t=8lQbnp9YiAA&_r=1",
        icon: TTIcon,
      },
    ],
  };
});

function getCurrentYear(): number {
  const currentDate = new Date();
  return currentDate.getFullYear();
}
</script>

<template>
  <div class="footer">
    <div class="grid grid-cols-2 px-8 py-10">
      <div class="flex flex-col items-center justify-between space-y-20">
        <div class="flex gap-20">
          <a
            v-for="item in navigation.solutions"
            :key="item.name"
            :href="item.href"
            class="footer-links"
            target="_blank"
          >
            {{ item.name }}
          </a>
        </div>
        <div class="footer-col2">
          <p class="footer-text">&copy; {{ getCurrentYear() }} TopFluenz, V1.0.</p>
        </div>
      </div>
      <div class="flex flex-col items-center justify-between">
        <div class="flex gap-20">
          <a
            v-for="item in navigation.legal"
            :key="item.name"
            :href="item.href"
            class="footer-links"
            target="_blank"
          >
            {{ item.name }}
          </a>
        </div>
        <div class="footer-col1">
          <a
            v-for="item in navigation.social"
            :key="item.name"
            :href="item.href"
            class="footer-icon"
            target="_blank"
          >
            <component :is="item.icon" aria-hidden="true" class="h-7 w-7" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  @apply bg-transparent;

  &-container {
    @apply mx-auto max-w-7xl px-6 py-12 md:flex md:flex-col md:items-center md:justify-between lg:px-8;
  }

  &-col1 {
    @apply flex justify-center space-x-6 md:order-2;
  }

  &-col2 {
    @apply md:order-1 md:mt-0;
  }

  &-icon {
    @apply text-jordy-200 hover:text-jordy-400;
  }

  &-links {
    @apply text-basic-300 hover:text-basic-500;
  }

  &-text {
    @apply text-center text-sm leading-5 text-basic-500;
  }
}
</style>
